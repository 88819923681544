<script>
  import Header from './Header.svelte'
  import NoButton from './NoButton.svelte'
  import Question from './Question.svelte'
  import { sessionStore, responsesStore } from '../session-store.js'
  import { onMount, onDestroy } from 'svelte';
  export let exam_session
  export let exam
  export let participant
  export let duration
  export let original
  export let questions
  export let active_question = 0
  export let finish_link
  export let online;

  onMount(() => {
    online = navigator.onLine;
    window.addEventListener('online', e => (online = true), false);
    window.addEventListener('offline', e => (online = false), false);
    sessionStore.set(exam_session)
    responsesStore.set(exam_session.responses)
  });
  onDestroy(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('online', null);
      window.removeEventListener('offline', null);
    }
  });
  function prev() {
    active_question -= 1
  }
  function next() {
    active_question += 1
  }
</script>
<style>
  .quiz-body {
    font-size: larger;
  }
</style>
<Header exam={exam} participant={participant} original={original} finish_link={ finish_link } duration={ duration } online={ online } />
<div class="quiz row">
  <div class="col-md-auto pt-2">
    <!-- {#each exam_session.responses as response, i} -->
    {#each $responsesStore as response, i}
      <NoButton bind:response={ response } index={ i } bind:active_question={ active_question } />
      {#if (i+1)%5 === 0}
        <br/>
      {/if}
    {/each}
  </div>
  
  <div class="col pt-2 quiz-body">
    <!-- {#each exam_session.responses as response, i} -->
    {#each $responsesStore as response, i}
      <!-- <Question bind:exam_session={ exam_session } active_question={ active_question } i={ i } questions={ questions } bind:response={ response } /> -->
      <Question bind:exam_session={ $sessionStore } bind:active_question={ active_question } i={ i } questions={ questions } bind:response={ response } online={ online } />
    {/each}
    <div class="form-row">
      <div class="offset-1">
        {#if active_question != 0}
          <button type="button" class="btn btn-outline-primary" on:click={prev}>Sebelumnya</button>
        {/if}
        {#if active_question != $responsesStore.length - 1}
          <button type="button" class="btn btn-outline-primary" on:click={next}>Berikutnya</button>
        {/if}
      </div>
    </div>
  </div>

</div>