import { writable } from "svelte/store"
// import Api from "./api"

// export let sessionStore = writable([])

// export const sessionStore = writable(1)
export const sessionStore = writable({})
export const responsesStore = writable([])

// export const updateSession = session =>
  // Api.put('/dashboard/ujian/peserta-ujian/${session.id}.json', { session })