<script>
  export let exam
  export let participant
  import { tweened } from 'svelte/motion';
  export let original = 120 * 60; // TYPE NUMBER OF SECONDS HERE
  export let timer = tweened(original)
  export let finish_link
  export let duration = duration
  export let online

  // ------ dont need to modify code below
  setInterval(() => {
    if ($timer > 0) $timer--;
    if ($timer <=0) post(finish_link);
  }, 1000);

  $: hours = twoDigits(Math.floor($timer / 3600))
  $: minutes = twoDigits(Math.floor($timer / 60) % 60)
  // $: seconds = Math.floor($timer - minutes * 60)
  $: seconds = twoDigits(Math.floor($timer % 60))

  function twoDigits(value) {
    if(value.toString().length <= 1)
      {
        return "0"+value.toString();
      }
    return value.toString();
  }

  function post(path, method='post') {

    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    document.body.appendChild(form);
    form.submit();
  }

</script>

<style>
  /* hr {
    border-top-width: 5px;
  } */
  progress {
    width: 100%;
  }
</style>

<div class="form-row">
  <div class="col">
    <h5>
      Computer Based Test <br>
      { exam.name }
    </h5>
  </div>
  <div class="col-2 text-center text-monospace">
    <div>
      <!-- <span> -->
        {#if timer < 1}
          waktu habis
        {:else}
          <span class="btn btn-danger">
            {hours}:{minutes}:{seconds}
          </span>  
          <br>
          <!-- <progress value={$timer/original}></progress> -->
        {/if}
      <!-- </span> -->
    </div>
    <div>
      {#if online}
        <span class="badge badge-pill badge-success">online</span>
      {:else}
        <span class="badge badge-pill badge-danger">offline</span>
      {/if}
    </div>
  </div>
  <div class="col text-right">
    { participant.name } <br>
    { participant.uic }
  </div>
</div>
<div class="row">
  <div class="col"><progress value={$timer/duration}></progress></div>
</div>
