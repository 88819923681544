<script>
  export let response
  export let index
  export let active_question
  function jump(i) {
    active_question = i
    // alert(i + '-' + active_question)
  }
</script>
<style>
  .btn {
    width: 40px;
  }
</style>
<!-- { response["checked"] } -->
<button class="btn btn-sm { active_question === index ? 'btn-primary' : response["checked"] ? 'btn-warning' : response["choice"] === '' ? 'btn-secondary' : 'btn-success' }  text-right mb-1" on:click={jump(index)} >{ response["no"] }</button>