import $ from 'jquery'
global.$ = $
global.jQuery = $

require("@rails/ujs").start()

import 'bootstrap'
import WebpackerSvelte from "webpacker-svelte"
// import Home from "../src/components/Home.svelte"
import Quiz from "../src/components/Quiz.svelte"
// import LoginPage from "../src/components/LoginPage.svelte"

WebpackerSvelte.setup({ Quiz })
// WebpackerSvelte.setup({ Home, Quiz })
// WebpackerSvelte.setup({ Home, LoginPage })