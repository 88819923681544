<script>
  export let exam_session
  export let active_question
  export let i
  export let questions
  export let response
  export let online
  let chosen = response["choice"]
  // function prev() {
  //   active_question -= 1
  // }
  // function next() {
  //   active_question += 1
  //   alert(active_question)
  // }
  function choose(i,c) {
    if (online) {
      chosen = c
      exam_session.responses[i]["choice"] = c
      exam_session.responses[i]["checked"] = response.checked
      if (c.toUpperCase() === exam_session.responses[i]["correct_answer"].toUpperCase()) {
        exam_session.responses[i]["score"] = 1
      } else {
        exam_session.responses[i]["score"] = 0
      }
      updateSession(exam_session)
    } else {
      alert('Anda sedang offline, jawaban tidak dapat terkirim. Tunggu hingga status di bawah timer menunjukkan "online".')      
    }
  }
  async function updateSession(exam_session) {
    let url = '/quiz/' + exam_session.id + '/answer'
    const res = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(exam_session.responses)
    })
  }
  // let disabled = true
  // function prev() {
  //   active_question -= 1
  // }
  // function next() {
  //   active_question += 1
  // }
</script>
<style>
  li {
    cursor:pointer;
  }
</style>
{#if active_question == i}
<div class="form-row">
  <div class="col-1 text-right">{ i + 1 }</div>
  <div class="col">
    <div class="vignette">
      {#if questions[response["question_uic"]]["vignette"]}
        {@html questions[response["question_uic"]]["vignette"] }
      {/if}
    </div>
    <div class="illustration">
      {#if questions[response["question_uic"]]["image"]}
        <img src="https://ujiteori.kdgi.or.id/img/{questions[response["question_uic"]]["image"]}" alt="">
      {/if}
    </div>
    <div class="question py-1">
      {#if questions[response["question_uic"]]["question"]}
        {@html questions[response["question_uic"]]["question"] }
      {/if}
    </div>
    <div class="choices pt-3">
      <ul class="list-group">
        {#each questions[response.question_uic].choices as choice}
        <li class="list-group-item { chosen == choice.no ? 'font-weight-bolder text-primary' : '' }" on:click={choose(i, choice.no)}>
          { choice.no }. <span>{@html choice.text }</span>
        </li>
        {/each}
      </ul>
      <!-- {questions[response.question_uic].answer} -->
    </div>
    <div class="pt-3">
      <label>
        <!-- <input type=checkbox bind:checked={exam_session.responses[i].checked} on:click={updateSession(exam_session)}> -->
        <input type=checkbox bind:checked={response.checked}>
        Tandai soal
      </label>
    </div>
  </div>
</div>

<!-- <div class="form-row">
  <div class="offset-1">
    <button type="button" class="btn btn-outline-primary" on:click={prev} {disabled}>Sebelumnya</button>
    <button type="button" class="btn btn-outline-primary" on:click={next}>Berikutnya</button>
  </div>
</div> -->
{/if}